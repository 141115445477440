/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch } from 'redux'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'
import { ActionResponse, Id } from 'util/types/index'
import { Attendant, AttendantTypes } from './types'

export function createAttendant (storeId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Attendant>> => {
    return Axios
      .post(`/store/${storeId}/attendant`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: AttendantTypes.ATTENDANT_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o atendente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)
        return res
      })
  }
}

export function updateAttendant (storeId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Attendant>> => {
    return Axios
      .put(`/store/${storeId}/attendant/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: AttendantTypes.ATTENDANT_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o atendente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteAttendant (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Attendant>> => {
    return Axios
      .delete(`/store/${storeId}/attendant/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: AttendantTypes.ATTENDANT_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o atendente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getAttendant (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Attendant>> => {
    return Axios
      .get(`/store/${storeId}/attendant/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: AttendantTypes.ATTENDANT_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o atendente')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getAttendantList (storeId: Id, params: any = {}) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Attendant>> => {
    return Axios
      .get(`/store/${storeId}/attendant`, { params })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: AttendantTypes.ATTENDANT_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}
