import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { ReactComponent as ContactCard } from 'assets/icons/address-card.svg'

import { ApplicationState } from 'AppReducer'
import { formatDateTime, maskPhone } from 'util/masks'
import { deletePerson, getPersonList } from '../redux/action/person'

import Pagination from 'util/modules/Pagination'
import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const PersonList: React.FC = () => {
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState<number>()
  const [filter, setFilter] = useState({ name: '', contact: '' })
  const [alertModal, setAlertModal] = useState(false)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { persons: { items, pagination } } = useSelector((state: ApplicationState) => state.personReducer)
  const history = useHistory()
  const { location: { search } } = history

  function handleRemove (id: number): void {
    if (selected) {
      deletePerson(selected, id)(dispatch).then(res => {
        if (res.status === 'success') {
          setDeleteId(undefined)
          history.push('/panel/person')
        }
      })
    }
  }

  function handleFilter (e: React.FormEvent) {
    e.preventDefault()

    if (selected) {
      const query: any = {
        page: 1,
        limit: 10,
        fields: 'id,name,alias,createdAt,updatedAt'
      }

      if (filter.name) query['filter_like[name]'] = `^${filter.name}$`
      if (filter.contact) query['filter_like[contacts.value]'] = `^${filter.contact}$`

      getPersonList(selected, query)(dispatch)
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(search)

    if (selected) {
      getPersonList(selected, {
        page: query.get('page') || 1,
        limit: 10,
        fields: 'id,name,alias,createdAt,updatedAt'
      })(dispatch)
    }
  }, [selected, search])

  return (
    <div className="list" style={{ marginTop: 0 }}>
      <form onClick={handleFilter} className="row padding-16 align-end" style={{ marginTop: -32 }}>
        <div className="form-control flex">
          <label>Nome</label>
          <input
            type="text"
            value={filter.name}
            onChange={(e): void => setFilter({ ...filter, name: e.target.value })}
          />
        </div>
        <div className="form-control flex">
          <label>Telefone</label>
          <input
            type="text"
            value={filter.contact}
            onChange={(e): void => setFilter({ ...filter, contact: e.target.value })}
          />
        </div>
        <button className="gradient" style={{ height: 48 }}><i className="fa fa-filter"/></button>
      </form>
      <div className="list-header">
        <div>Id</div>
        <div>Nome</div>
        <div>Contatos</div>
        <div>Atualizado em</div>
        <div>Ações</div>
      </div>

      {
        items.length === 0 &&
        <div className="list-row empty">
          <ContactCard width={120} height={120} style={{ padding: 24 }} />
          <div className="grid-span" style={{ height: 0 }}>Nenhum contato cadastrado</div>
        </div>
      }

      {
        items.map((item, i) => {
          const alias = item.alias ? `(${item.alias})` : ''
          const tag = item.tag ? (
            <span
              className="badge-pill margin-left-8"
              style={{ background: item.tag.color, color: 'white', width: 'auto' }}
            >
              { item.tag.name }
            </span>
          ) : null
          return (
            <div className="list-row" key={i}>
              <div data-content="Id" className="bold">#{item.id}</div>
              <div data-content="Nome" style={{ display: 'flex' }}>{item.name} {alias} { tag }</div>
              <div data-content="Contatos">
                {
                  item.contacts?.map(item => {
                    const label = {
                      PENDING: { icon: 'clock', color: 'gray' },
                      INVALID: { icon: 'times', color: 'red' },
                      VALIDATED: { icon: 'check', color: 'green' }
                    }[item.status || 'PENDING']

                    const icon = <i className={`fa fa-${label.icon}`} style={{ color: label.color }}/>

                    return <div key={item.id}>{ icon }{ maskPhone(item.value) }</div>
                  })
                }
              </div>
              <div data-content="Atualizado em">{formatDateTime(item.updatedAt)}</div>
              <div data-content="Ações" className="row buttons-list-row">
                <div className="row">
                  {
                    hasPermission('PERSON:UPDATE') &&
                  <NavLink to={`/panel/person/${item.id}/edit`} className="button">
                    <i className="fas fa-edit" /> &nbsp;Editar
                  </NavLink>
                  }
                  {
                    hasPermission('PERSON:DELETE') &&
                  <span
                    className="button"
                    onClick={() => setDeleteId(item.id)}>
                    <i className="fas fa-trash-alt" /> &nbsp;Excluir
                  </span>
                  }
                </div>
              </div>
            </div>
          )
        })
      }

      <DeleteConfirm
        id={deleteId}
        onCancel={() => {
          setDeleteId(undefined)
          setAlertModal(false)
        }}
        onConfirm={(id) => handleRemove(id)}
      />
      <div className="row padding-16">
        <Pagination pagination={pagination} />
      </div>

      <span style={{
        position: 'fixed',
        top: 12,
        right: 12,
        backgroundColor: 'orange',
        borderRadius: 4,
        padding: 16,
        color: 'black',
        display: alertModal ? 'block' : 'none',
        zIndex: 9999
      }}>
        <h4>Cliente possui atendimento em andamento</h4>
      </span>
    </div>
  )
}

export default PersonList
