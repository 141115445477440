import z from 'zod'

export const ChatBotConfigSchema = z.object({
  enabled: z.boolean().default(true),
  configSector: z.object({
    redirectAuto: z.boolean().default(false),
    redirectSectorId: z.string().optional().nullable(),
    redirectAutoInMinutes: z.number().default(0),

    redirectWrongMenu: z.boolean().default(true),
    redirectWrongMenuAttempts: z.number().default(5)
  }),

  llmEnabled: z.boolean().default(false),
  openIaApiKey: z.string().optional().nullable(),
  openIaAssistantId: z.string().optional().nullable(),

  redirectSectorMessage: z.string().optional().nullable(),
  redirectSectorMessageEnabled: z.boolean().default(false),

  redirectAttendantMessage: z.string().optional().nullable(),
  redirectAttendantMessageEnabled: z.boolean().default(false)
})

export type ChatBotConfigSchemaType = z.infer<typeof ChatBotConfigSchema>;
