import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import './styles/Attendant.scss'

import { getAttendant } from './redux/actions'
import { ApplicationState } from 'AppReducer'

import Modal from 'util/modules/Modal'
import AttendantList from './components/AttendantList'
import AttendantsForm from './components/AttendantForm'
import { AttendantTypes } from './redux/types'
import { hasPermission } from 'util/services/AuthService'

const Attendant: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { id, action } = useParams<{ [x: string]: string }>()

  function handleCloseForm () {
    setFormOpen(false)
    history.push('/panel/attendant')
  }

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
  }, [action])

  useEffect(() => {
    if (selected && id) {
      getAttendant(selected, id)(dispatch)
    } else {
      dispatch({ type: AttendantTypes.ATTENDANT_GET, payload: undefined })
    }
  }, [id, selected])

  return (
    <div className="row panel bordered">
      <div className="row attendants-container">
        <div className="row padding-24">
          <p className="grow" style={{ fontSize: 18 }}>Atendentes Cadastrados</p>
          {
            hasPermission('ATTENDANT:CREATE') &&
            <button className="gradient radius" onClick={() => setFormOpen(true)}>
              Cadastrar
            </button>
          }
        </div>
        { hasPermission('ATTENDANT:LIST') && <AttendantList storeId={selected} /> }

        <Modal isOpen={isFormOpen} onClose={handleCloseForm}>
          <AttendantsForm id={Number(id)} isOpen={isFormOpen} onClose={handleCloseForm} />
        </Modal>
      </div>
    </div>
  )
}

export default Attendant
