import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { formatDateTime } from 'util/masks'
import { deleteAttendant, getAttendantList } from '../redux/actions'
import { ApplicationState } from 'AppReducer'

import { ReactComponent as UserAttendants } from 'assets/icons/user-tie.svg'
import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

interface AttendantListProps {
  storeId?: number
}

const AttendantList: React.FC<AttendantListProps> = ({ storeId }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [filter, setFilter] = useState({ isActive: undefined, name: '', email: '' })

  const [deleteId, setDeleteId] = useState<number>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendants } = useSelector((state: ApplicationState) => state.attendantReducer)

  function handleRemove (id: number): void {
    if (selected) {
      deleteAttendant(selected, id)(dispatch).then(res => {
        if (res.status === 'success') {
          setDeleteId(undefined)
          history.push('/panel/attendant')
        }
      })
    }
  }

  async function fetchAttendants (e?: React.FormEvent): Promise<void> {
    e?.preventDefault()
    let isActive
    if (filter.isActive === '1') {
      isActive = 1
    } else if (filter.isActive === '0') {
      isActive = 0
    } else {
      isActive = undefined
    }

    const params: any = {
      'filter[isActive]': isActive
    }

    if (filter.name) params['filter_like[p.name]'] = filter.name
    if (filter.name) params['filter_or[p.alias]'] = filter.name
    if (filter.email) params['filter_like[p.email]'] = filter.email

    if (storeId) getAttendantList(storeId, params)(dispatch)
  }

  useEffect(() => {
    fetchAttendants()
  }, [dispatch])

  return (
    <form onSubmit={fetchAttendants}>
      <div className="row panel align-end filter">
        <div className="form-control">
          <label>Nome</label>
          <input
            type="text"
            value={filter.name}
            onChange={(e): void => setFilter({ ...filter, name: e.target.value })} />
        </div>
        <div className="form-control">
          <label>E-mail</label>
          <input
            type="text"
            value={filter.email}
            onChange={(e): void => setFilter({ ...filter, email: e.target.value })} />
        </div>
        <div className="form-control">
          <label>Ativo</label>
          <select
            value={filter.isActive}
            onChange={(e): void => setFilter({ ...filter, isActive: (e.target.value as any) })}
          >
            <option value={undefined}>Nenhum</option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </div>
        <button className="gradient button"><i className="fa fa-filter" />Filtrar</button>
      </div>
      <div className="list" style={{ marginTop: 0 }}>
        <div className="list-header">
          <div>Id</div>
          <div>Nome</div>
          <div>Setor</div>
          <div>Criado em</div>
          <div>Ativo</div>
          <div>Ações</div>
        </div>

        {
          attendants.length === 0 &&
        <div className="list-row empty">
          <UserAttendants width={120} height={120} style={{ padding: 24 }} />
          <div className="grid-span" style={{ height: 0 }}>Nenhum setor cadastrado</div>
        </div>
        }

        {
          attendants.map((attendant, i) => {
            return (
              <div className="list-row" key={i}>
                <div data-content="Id" className="bold">#{attendant.id}</div>
                <div data-content="Nome">{ attendant.user.person?.firstName }</div>
                <div data-content="Setor">
                  { attendant.sectorAttendants?.map(item => item.sector?.name).join(', ') }
                </div>
                <div data-content="Criado em">{ formatDateTime(attendant.createdAt) }</div>
                <div data-content="Ativo">
                  <span className={`status ${attendant.isActive ? 'success' : 'danger'}`}>
                    {attendant.isActive ? 'Sim' : 'Não'}
                  </span>
                </div>
                <div data-content="Ações" className="row buttons-list-row">
                  <div className="row">
                    {
                      hasPermission('ATTENDANT:UPDATE') &&
                    <NavLink to={`/panel/attendant/${attendant.id}/edit`} className="button flex">
                      <i className="fas fa-edit" /> &nbsp;Editar
                    </NavLink>
                    }
                    {
                      hasPermission('ATTENDANT:DELETE') &&
                    <span className="button secondary flex" onClick={() => setDeleteId(attendant.id)}>
                      <i className="fas fa-trash-alt" /> &nbsp;Excluir
                    </span>
                    }
                  </div>
                </div>
              </div>
            )
          })
        }

        <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
      </div>
    </form>
  )
}

export default AttendantList
