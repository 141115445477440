import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../ProfileTab'
import { UploadRef } from 'util/modules/Upload'
import { ApplicationState } from 'AppReducer'

import Upload from 'util/modules/Upload'
import { deleteProfilePicture, updateProfile } from 'panel/user/redux/actions'

type Props = { onUnsaved: (tab?: Tab) => void }

const ProfileData: React.FC<Props> = ({ onUnsaved }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [picture, setPicture] = useState<File>()
  const [fetching, setFetching] = useState(false)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { logged } = useSelector((state: ApplicationState) => state.userReducer)

  const uploadRef = useRef<UploadRef>(null)

  const handleClear = useCallback(() => {
    if (uploadRef.current) {
      uploadRef.current.clear()
      setPicture(undefined)
    }
  }, [uploadRef, history])

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()
    if (!selected) return undefined

    const actualPicture = logged?.person.picture
    if (actualPicture && !picture) {
      await deleteProfilePicture()(dispatch)
    } else if (picture) {
      const formData = new FormData()
      formData.set('picture', picture)
      await updateProfile(formData)(dispatch).then(res => {
        if (res?.status === 'success') {
          handleClear()
        }
      })
    }

    setFetching(false)
  }

  useEffect(() => {
    if (uploadRef.current && logged?.person?.picture) {
      uploadRef.current.setPreviewImage(logged.person.picture)
    }
  }, [uploadRef, logged])

  // Handle Unsaved form
  useEffect(() => {
    const isUnsaved = !!picture
    onUnsaved(isUnsaved ? 'DATA' : undefined)
  }, [picture])

  useEffect(() => {
    return () => {
      handleClear()
    }
  }, [handleClear])

  if (!logged) return null

  return (
    <div className="row margin-top-16 profile-data">
      <div className="row">
        <div className="row" style={{ width: 120, height: 120 }}>
          <i className="fa fa-edit upload-button" />
          <Upload ref={uploadRef} placeholder={false} onChange={setPicture} />
        </div>
      </div>
      <div className="row align-end name-wrapper">
        <div className="form-control grow">
          <label>Primeiro Nome</label>
          <input
            required
            disabled
            type="text"
            value={logged.person.firstName}
          />
        </div>
        <div className="form-control grow">
          <label>Sobrenome</label>
          <input
            required
            disabled
            type="text"
            value={logged.person.lastName}
          />
        </div>
      </div>

      <div className="form-control row">
        <label>Nome de Usuário</label>
        <input
          type="text"
          required
          disabled
          value={logged.username}
        />
      </div>

      <div className="form-control row">
        <label>E-mail</label>
        <input
          required
          disabled
          type="text"
          value={logged.person.email}
        />
      </div>

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button" onClick={handleClear}>Limpar</span>
        <button className="gradient button" type='button' disabled={fetching} onClick={handleSubmit}>Salvar</button>
      </div>
    </div>
  )
}

export default ProfileData
