import React from 'react'

const Prompt: React.FC = () => {
  return (
    <iframe
      src="https://chat.underhub.com.br/prompt-under"
      style={{ border: 'none', width: '100%', height: '100%' }}
    />
  )
}

export default Prompt
